import { gql, useQuery } from "@apollo/client";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel, Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss/scrollbar";
import Loader from "../../Atoms/Loader";
import FeaturedBanner from "./FeaturedBanner";
import styles from "./FeaturedBanners.module.scss";

interface FeaturedProps {
  showHome?: boolean;
  locationId?: string;
  locationName?: string;
}

export default function FeaturedBanners({
  showHome,
  locationId,
  locationName,
}: FeaturedProps) {
  const { loading, error, data } = useQuery(GET_FEATURED_BANNERS, {
    variables: {
      showHome,
      locationId,
    },
    fetchPolicy: "cache-and-network",
  });
  if (loading) {
    return <Loader />;
  }
  if (error || data.featuredBanners.length === 0) {
    return <></>;
  }

  const { featuredBanners } = data;

  return (
    <div className="AppLayout">
      <h2 className="UIT UITSLL UITW1 UITC0 HomeCityHeader">
        Find Things to Do in {locationName} For Every Interest
      </h2>

      <div className={styles.FeaturedBannersContainer}>
        <Swiper
          style={{ paddingBottom: 15 }}
          scrollbar={{
            el: ".swiper-scrollbar",
            draggable: true,
            dragSize: 50,
          }}
          effect="slide"
          slidesPerView={3.05}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            400: {
              slidesPerView: 1.05,
              spaceBetween: 10,
            },
            425: {
              slidesPerView: 1.25,
              spaceBetween: 10,
            },

            640: {
              slidesPerView: 1.5,
            },
            767: {
              slidesPerView: 1.9,
            },
            839: {
              slidesPerView: 2.45,
            },
            1024: {
              slidesPerView: 3.05,
            },
          }}
          freeMode={true}
          cssMode={true}
          navigation={true}
          mousewheel={{
            sensitivity: 1,
            releaseOnEdges: true,
          }}
          modules={[Mousewheel, Navigation, Scrollbar]}
          spaceBetween={10}
          wrapperClass={styles.FeaturedSlideWrapper}
        >
          {featuredBanners.map((banner, index) => {
            return (
              <SwiperSlide key={index}>
                <FeaturedBanner banner={banner} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export const GET_FEATURED_BANNERS = gql`
  query FeaturedBanners($showHome: Boolean, $locationId: ID) {
    featuredBanners(showHome: $showHome, locationId: $locationId) {
      title
      imageUrl
      pageUrl
      ctaText
    }
  }
`;
